var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-4" },
        [
          _c("v-card-title", { staticClass: "font-weight-light pb-0" }, [
            _vm._v("Lump Sums")
          ]),
          _c(
            "v-container",
            { staticClass: "white" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "div",
                    { staticClass: " grey lighten-4 pa-2" },
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: { height: "60px" },
                          attrs: { dense: "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                ref: "lumpAmount",
                                attrs: {
                                  "background-color": "white",
                                  dense: "",
                                  label: "Amount",
                                  outlined: "",
                                  prefix: "$",
                                  "lazy-validation": ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("active")
                                  },
                                  change: function($event) {
                                    return _vm.formatAmount($event)
                                  }
                                },
                                model: {
                                  value: _vm.lump.lump_allow,
                                  callback: function($$v) {
                                    _vm.$set(_vm.lump, "lump_allow", $$v)
                                  },
                                  expression: "lump.lump_allow"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "background-color": "white",
                                  dense: "",
                                  hint: "Press Enter to Save",
                                  label: "Description",
                                  outlined: "",
                                  "lazy-validation": ""
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.addLumpSum(_vm.lump)
                                  }
                                },
                                model: {
                                  value: _vm.lump.lump_description,
                                  callback: function($$v) {
                                    _vm.$set(_vm.lump, "lump_description", $$v)
                                  },
                                  expression: "lump.lump_description"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "ma-1",
                                            attrs: { icon: "", tabindex: "-1" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addLumpSum(_vm.lump)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "green" } },
                                            [_vm._v("mdi-plus")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [_c("span", [_vm._v("Add")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "div",
                    {
                      staticClass: "grey lighten-4 px-2 pt-3",
                      staticStyle: { height: "255px", "overflow-y": "auto" }
                    },
                    _vm._l(_vm.lumpSums, function(lump, idx) {
                      return _c(
                        "v-row",
                        {
                          key: idx,
                          staticStyle: { height: "50px" },
                          attrs: { align: "center", dense: "" }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    function(v) {
                                      return !!v || "Amount Required"
                                    }
                                  ],
                                  "background-color": "white",
                                  dense: "",
                                  outlined: "",
                                  prefix: "$"
                                },
                                model: {
                                  value: lump.lump_allow,
                                  callback: function($$v) {
                                    _vm.$set(lump, "lump_allow", $$v)
                                  },
                                  expression: "lump.lump_allow"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    function(v) {
                                      return !!v || "Description Required"
                                    }
                                  ],
                                  "background-color": "white",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value: lump.lump_description,
                                  callback: function($$v) {
                                    _vm.$set(lump, "lump_description", $$v)
                                  },
                                  expression: "lump.lump_description"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mb-6 mx-1",
                                              attrs: {
                                                tabindex: "-1",
                                                icon: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteLumpSum(lump)
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "red" } },
                                              [_vm._v("mdi-trash-can-outline")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v("Delete")])]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }