<template>
  <v-col>
    <v-card class="grey lighten-4">
      <v-card-title class="font-weight-light pb-0">Lump Sums</v-card-title>
      <v-container class="white">
        <v-form ref="form">
          <div class=" grey lighten-4 pa-2">
            <v-row dense style="height:60px;">
              <v-col cols="3">
                <v-text-field
                  @click="$emit('active')"
                  background-color="white"
                  dense
                  label="Amount"
                  outlined
                  prefix="$"
                  ref="lumpAmount"
                  @change="formatAmount($event)"
                  v-model="lump.lump_allow"
                  lazy-validation
                />
              </v-col>
              <v-col>
                <v-text-field
                  @keydown.enter="addLumpSum(lump)"
                  background-color="white"
                  dense
                  hint="Press Enter to Save"
                  label="Description"
                  outlined
                  v-model="lump.lump_description"
                  lazy-validation
                />
              </v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    tabindex="-1"
                    class="ma-1"
                    @click="addLumpSum(lump)"
                  >
                    <v-icon color="green">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-row>
          </div>
          <v-divider />
          <div
            class="grey lighten-4 px-2 pt-3"
            style="height: 255px; overflow-y: auto;"
          >
            <v-row
              v-for="(lump, idx) in lumpSums"
              :key="idx"
              align="center"
              style="height:50px;"
              dense
            >
              <v-col cols="3">
                <v-text-field
                  :rules="[(v) => !!v || 'Amount Required']"
                  background-color="white"
                  dense
                  outlined
                  prefix="$"
                  v-model="lump.lump_allow"
                />
              </v-col>
              <v-col>
                <v-text-field
                  :rules="[(v) => !!v || 'Description Required']"
                  background-color="white"
                  dense
                  outlined
                  v-model="lump.lump_description"
                />
              </v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    tabindex="-1"
                    v-on="on"
                    class="mb-6 mx-1"
                    icon
                    @click="deleteLumpSum(lump)"
                  >
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'LumpSums',
  props: ['lumpSums'],
  mixins: [notification],
  data() {
    return {
      confirmDialog: false,
      lump: {},
      lumps: [],
      pageSize: 500,
      selectedLump: {},
    }
  },
  methods: {
    addLumpSum(lump) {
      const valid = lump.lump_allow && lump.lump_description
      if (valid) {
        this.$emit('add', lump)
        this.lump = {}
        this.$refs.form.resetValidation()
        this.notify('success', 'Lump Sum Successfully Added')
      } else {
        this.notify('error', 'Missing Required Field(s)')
      }
      this.$refs.lumpAmount.focus()
    },
    deleteLumpSum(lumpSum) {
      this.$emit('delete', lumpSum)
      this.notify('success', 'Lump Successfully Deleted')
    },
    formatCurrency(value) {
      this.lump.lump_allow = ((value * 100) / 100).toFixed(2)
    },
    formatAmount(value) {
      this.lump.lump_allow = parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/global.css';
</style>
